<template>
  <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 16px 24px;" @dblclick="loadCampaign">
    <div style="display: flex; flex-direction: column; gap: 16px; width: 80%; margin-right: 32px;">
      <div style="display: flex; flex-direction: row; gap: 24px; width: 100%; align-items: center;">
        <div class="h4" style="color: var(--color_brand_xdark)">{{ this.dataCampaign.data.name }}</div>
        <div class="body3" style="color: var(--color_gray_medium)"><b>{{ this.$langGetValue('biaCreation') }}: </b>{{
          this.formatDate(this.dataCampaign.data.dateOfStart)
        }}
        </div>
        <div class="body3" style="color: var(--color_gray_medium)"><b>{{ this.$langGetValue('biaExpectedConclusion') }}:
          </b>{{ this.formatDate(this.dataCampaign.data.estimatedDateOfConclusion) }}</div>
      </div>
      <div v-if="campaignAreasList.length < 20" class="flex-wrap"
        style="display: flex; flex-direction: row; gap: 8px; width: 100%;">
        <Chip class="primaryChip" v-for="(area, index) in campaignAreasList" :key="index" :label="area.label" v-tooltip.top="area.path"></Chip>
      </div>
      <div v-else class="flex-wrap expandChips" style="display: flex; flex-direction: row; gap: 8px; width: 100%;">
        <Chip class="primaryChip" v-for="(area, index) in limitedCampaignAreasList()" :key="index" :label="area.label" v-tooltip.top="area.path"></Chip>
        <Chip class="secondaryChip" :label="getLabel(campaignAreasList)" @click="addAreaChips(20)"
          style="cursor: pointer">
        </Chip>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; gap: 32px; width: 20%; align-items: center; justify-content: end;">
      <!-- <ProgressBar :percentual="50" /> -->
      <Divider layout="vertical" style="height: 100%;" />
      <i class="pi pi-search-plus" v-tooltip.top="{ value: this.$langGetValue('buttonView') }" @click="loadCampaign"
        style="font-size: 1.1rem; cursor: pointer"></i>
    </div>
  </div>
</template>

<script>
import "../Global.css";
// import ProgressBar from "./ProgressBar.vue";
import Util from "../service/Util";

export default {
  components: {
    // ProgressBar,
  },
  props: {
    dataCampaign: Array,
    hierarchy: Array,
  },
  data() {
    return {
      // daysWithoutUpdate: null,
      campaignAreasList: [],
    };
  },
  mounted() {
    Util.loadRequest()

    this.dataCampaign.data.areaId.forEach((area) => {
      this.campaignAreasList.push(this.getAreaNameById(area))
    })

    Util.loadedRequest()
  },
  methods: {
    addAreaChips(value) {
      var oldChip = document.querySelector('.secondaryChip');

      if (oldChip) {
        var chipValue = oldChip.textContent.replace(/[+\s]/g, '');

        chipValue = parseInt(chipValue, 10);

        oldChip.remove();

        var expandChips = document.getElementsByClassName('expandChips')[0];

        if (chipValue > 10) {
          for (let i = value + 1; i <= value + 10; i++) {

            var chipOne = document.createElement('div');
            chipOne.className = 'p-chip p-component primaryChip';

            var chipTextOne = document.createElement('div');
            chipTextOne.className = 'p-chip-text';
            chipTextOne.textContent = this.campaignAreasList[i];

            chipOne.appendChild(chipTextOne);

            expandChips.appendChild(chipOne);
          }

          var chipExpand = document.createElement('div');
          chipExpand.className = 'p-chip p-component secondaryChip';
          chipExpand.style.cursor = 'pointer';

          var chipExpandText = document.createElement('div');
          chipExpandText.className = 'p-chip-text';
          chipExpandText.textContent = "+ " + (chipValue - 10)

          chipExpand.appendChild(chipExpandText);

          expandChips.appendChild(chipExpand);

          chipExpand.addEventListener('click', function () {
                this.addAreaChips(value + 10);
            }.bind(this));
        }
        else {
          for (let i = value + 1; i < this.campaignAreasList.length; i++) {

            var chipTwo = document.createElement('div');
            chipTwo.className = 'p-chip p-component primaryChip';

            var chipTextTwo = document.createElement('div');
            chipTextTwo.className = 'p-chip-text';
            chipTextTwo.textContent = this.campaignAreasList[i];

            chipTwo.appendChild(chipTextTwo);

            expandChips.appendChild(chipTwo);
          }
        }
      }
    },
    getLabel(array) {
      var size = array.length - 20

      return "+ " + size
    },
    limitedCampaignAreasList() {
      return this.campaignAreasList.slice(0, 21);
    },
    formatDate(inputDate) { //Alteração do formatDate para usar da Util
      return Util.formatDate(inputDate, false)
    },
    getNamesArrayOfAreas(array, arrayTarget) {
      array.forEach((item) => {
        arrayTarget.push(this.getAreaNameById(item))
      })
    },
    getAreaNameById(id) {
      var areaName = null;

      areaName = this.hierarchy.filter(area => area.id == id);

      return areaName[0];
    },
    loadCampaign() {
      window.open(
        window.location.href + "/campaign?id=" + this.dataCampaign.id,
        "_blank"
      );
    }
  },
};
</script>
<style>
.p-chip .p-chip-text {
  margin: 4px 0px
}
</style>
